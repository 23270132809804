/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import { Text, Icon } from '@verso/components';
import TimesIcon from '../../icons/times';

function FilterTag({ label, count, field, active, onSelectTag }) {
  const activeStyle = active
    ? {
        backgroundColor: 'alerts.information',
        color: 'textWhite',
        fill: 'textWhite',
      }
    : {};
  return (
    <Flex
      onClick={onSelectTag && onSelectTag({ label, field })}
      sx={{
        p: 2,
        m: 1,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'inline-flex',
        borderRadius: 'small',
        color: 'textMediumEmphasis',
        backgroundColor: 'neutral.0',
        ...activeStyle,
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <span
        sx={{
          display: 'flex',
          position: 'relative',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text sx={{ pb: '2px' }} variant="caption">
          {label} ({count}){' '}
        </Text>
        {active && (
          <TimesIcon
            sx={{
              width: 1,
              height: 1,
              mr: 1,
              ml: 2,
              ...activeStyle,
            }}
          />
        )}
      </span>
    </Flex>
  );
}

export default FilterTag;
