/** @jsx jsx */
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';
import { Text, ChipFilter } from '@verso/components';
import { useResponsiveValue } from '@theme-ui/match-media';
import Footer from './Footer';

const assets = {
  logoDark: 'https://media.versoapp.com/Brand/Verso/Dark.svg',
  logoMobileDark: 'https://media.versoapp.com/Brand/Verso/Logo/Mark/Dark.svg',
  logoLight: 'https://media.versoapp.com/Brand/Verso/Light.svg',
  logoMobileLight: 'https://media.versoapp.com/Brand/Verso/Logo/Mark/Light.svg',
};
const links = [
  {
    label: 'Terms',
    url: 'https://versolearning.com/terms-of-use-privacy-policy/',
  },
  {
    label: 'Privacy',
    url: 'https://versolearning.com/terms-of-use-privacy-policy/#privacy',
  },
  {
    label: 'About',
    url: 'https://versolearning.com/',
  },
  {
    label: 'Help',
    url: 'https://support.versoapp.com',
  },
];

const MainContainer = ({
  fullScreen = false,
  centerContent = false,
  children,
  popularTags,
  onSelectTag,
  selectedFilterTags,
  ...props
}) => {
  const logoUrl = useResponsiveValue([
    assets.logoMobileLight,
    assets.logoLight,
  ]);

  return (
    // Additional flex div is used for IE 11 bug where a flex container will not respect
    // the min-height property.
    // https://stackoverflow.com/questions/40490954/flex-container-min-height-ignored-in-ie
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        {...props}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          justifyContent: 'space-between',
        }}
      >
        <div
          sx={{
            width: '100%',
            flex: '1 1 auto',
            ...(centerContent && {
              width: 'auto',
              marginLeft: 'auto',
              marginRight: 'auto',
            }),
            ...(!fullScreen && {
              pb: 6,
            }),
          }}
        >
          {children}
        </div>
        <div
          sx={{
            backgroundColor: 'primaryDark',
            color: 'textWhite',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            pt: 8,
            clipPath: 'ellipse(100vw 100% at bottom)',
          }}
        >
          <div sx={{ maxWidth: '600px', pb: 7, px: 4 }}>
            <div>Popular search terms</div>
            {popularTags.map(({ field, label }, i) => (
              <ChipFilter
                key={i}
                label={label}
                field={field}
                sx={{
                  mr: 2,
                  mb: 2,
                  mt: 2,
                  bg: 'primaryLight',
                  color: 'textWhite',
                  border: 'none',
                  ':hover': {
                    bg: 'primaryLight',
                  },
                }}
                onClick={() => {
                  onSelectTag({ label, field })();
                  window.scrollTo(0, 680);
                }}
                active={selectedFilterTags.find(tags => tags.label === label)}
              >
                {label}
              </ChipFilter>
            ))}
          </div>
          <div sx={{ maxWidth: '600px', pb: 4, px: 4 }}>
            <Text variant="heading6">
              We acknowledge the Traditional Owners of the land on which we
              develop Verso. We pay our respects to their Elders, past and
              present, and the Aboriginal Elders of other communities who may
              use our services.
            </Text>
          </div>
        </div>
        <Footer
          sx={{
            backgroundColor: 'primaryDark',
          }}
          logoUrl={logoUrl}
          logoAlt="Verso"
          links={links}
          variant="dark"
        />
      </div>
    </div>
  );
};

MainContainer.propTypes = {
  children: PropTypes.node.isRequired,
  simpleFooter: PropTypes.bool,
  // back compat (but still probably useful)
  fullScreen: PropTypes.bool,
  centerContent: PropTypes.bool,
  popularTags: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      field: PropTypes.string,
    }),
  ),
  onSelectTag: PropTypes.func,
  selectedFilterTags: PropTypes.arrayOf(PropTypes.shape),
};

export default MainContainer;
