/** @jsx jsx */
import PropTypes from 'prop-types';
import { jsx, Flex, Box } from 'theme-ui';
import {Image, Text} from '@verso/components'

const Footer = ({
  title,
  logoUrl,
  logoAlt,
  showPoweredBy,
  links,
  variant,
  ...props
}) => {
  return (
    <footer
      {...props}
      sx={{
        height: [8, 10],
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        py: [1, 5],
      }}
    >
      <div
        sx={{
          position: 'absolute',
          top: 0,
          left: '50%',
          height: '100%',
          width: '120%',
          borderRadius: '50% 50% 0 0',
          transform: 'translateX(-50%)',
          overflow: 'hidden',
          py: [1, 5],
        }}
      >
        {logoUrl && (
          <Flex
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              pb: 4,
            }}
          >
            <Image
              src={logoUrl}
              alt={logoAlt}
              sx={{
                maxHeight: [7, 9],
                maxWidth: 12,
                height: 'auto',
              }}
            />
          </Flex>
        )}
      </div>
      <Flex
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          p: [3, 5],
          width: '100%',
          justifyContent: ['center', 'space-between'],
          alignItems: 'flex-end',
        }}
      >
        <Box>
          {title && (
            <Text
              variant="subtitle"
              sx={{
                color: variant === 'dark' ? 'textWhite' : 'text',
                display: ['none', 'block'],
              }}
            >
              {title}
            </Text>
          )}
          {links && (
            <Text
              variant="caption"
              sx={{
                color: variant === 'dark' ? 'textWhite' : 'text',
                display: 'block',
              }}
            >
              {links &&
                links.map(link => (
                  <a
                    key={link.label}
                    href={link.url}
                    sx={{
                      mr: 2,
                      textDecoration: 'underline',
                      color: 'inherit',
                    }}
                  >
                    {link.label}
                  </a>
                ))}
            </Text>
          )}
        </Box>
        {showPoweredBy ? (
          <Flex sx={{ alignItems: 'flex-end', display: ['none', 'flex'] }}>
            <Text
              variant="caption"
              sx={{ color: variant === 'dark' ? 'white' : 'text', mr: 2 }}
            >
              Powered by
            </Text>
            <Image
              src={
                variant === 'dark'
                  ? 'https://media.versoapp.com/Brand/Verso/Logo/Mini/Light.svg'
                  : 'https://media.versoapp.com/Brand/Verso/Logo/Mini/Dark.svg'
              }
              alt="Verso"
            />
          </Flex>
        ) : (
          <Flex sx={{ alignItems: 'flex-end', display: ['none', 'flex'] }}>
            <Text
              variant="caption"
              sx={{ color: variant === 'dark' ? 'white' : 'text', mr: 2 }}
            >
              Proudly supported by
            </Text>
            <Image
              sx={{ height: 3 }}
              src={
                variant === 'dark'
                  ? 'https://media.versoapp.com/Brand/Verso/VicSRC_logo_simple_light.svg'
                  : 'https://media.versoapp.com/Brand/Verso/VicSRC_logo_simple_light.svg'
              }
              alt="Vic SRC"
            />
          </Flex>
        )}
      </Flex>
    </footer>
  );
};

Footer.propTypes = {
  title: PropTypes.string,
  logoUrl: PropTypes.string,
  logoAlt: PropTypes.string,
  showPoweredBy: PropTypes.bool,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  variant: PropTypes.oneOf(['light', 'dark']),
};

Footer.defaultProps = {
  title: null,
  logoUrl: null,
  logoAlt: null,
  showPoweredBy: false,
  links: null,
  variant: 'dark',
};

export default Footer;
