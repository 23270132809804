/** @jsx jsx */
import { jsx } from 'theme-ui';
import {
  Modal,
  ModalHeader,
  ModalContent,
  Paragraph,
  Heading,
  ModalControls,
  Dimmer,
  Image,
  Text,
  FlexCol,
  FlexColItem,
} from '@verso/components';
import PropTypes from 'prop-types';

const DashboardModal = ({ visible, onClose }) => {
  return (
    <Dimmer visible={visible} onClickOutside={onClose}>
      <Modal variant="large">
        <ModalControls onClose={onClose} />
        <ModalHeader>
          <Heading as="h3" variant="heading5">
            This is what your students will see
          </Heading>
        </ModalHeader>
        <ModalContent sx={{ mt: 'unset' }}>
          <Paragraph>
            Regularly checking in on students’ learning progress and well-being
            helps teachers to think about their impact and identify adjustments
            that will improve the learning experience for all students.
          </Paragraph>
          <Paragraph>
            Using Verso simplifies the check-in process and automates the
            creation of powerful insights to support professional inquiry and
            meaningful discussions with students, colleagues, a mentor and PLCs.
          </Paragraph>
          <Text variant="caption" sx={{ display: 'block' }}>
            Designed in partnership with
          </Text>
          <Image
            sx={{ mt: 2, mx: 'auto', maxWidth: 12 }}
            src="https://media.versoapp.com/Brand/Verso/VicSRC_logo_full_colored_light.svg"
          />
        </ModalContent>
        <ModalContent
          sx={{ '&&': { m: 'unset' }, p: 6, backgroundColor: 'neutral.0' }}
        >
          <FlexCol>
            <FlexColItem cols={6}>
              <Paragraph sx={{ mt: 0 }}>
                <Text variant="heading6">
                  Discover how students are progressing and explore their
                  progress and well-being over time
                </Text>
              </Paragraph>
              <Paragraph>
                <Text variant="heading6">
                  Gauge how students feel about each learning experience
                </Text>
              </Paragraph>
              <Paragraph>
                <Text variant="heading6">
                  Identify the classroom strategies that students find most
                  helpful
                </Text>
              </Paragraph>
              <Paragraph>
                <Text variant="heading6">
                  Support teacher reflection, planning and the curation of a
                  professional practice portfolio.
                </Text>
              </Paragraph>
            </FlexColItem>
            <FlexColItem cols={6}>
              <Image
                src="https://media.versoapp.com/Images/general/check-in-dashboard.png"
                sx={{ width: '100%' }}
              />
            </FlexColItem>
          </FlexCol>
        </ModalContent>
      </Modal>
    </Dimmer>
  );
};

DashboardModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default DashboardModal;
