/** @jsx jsx */
import { jsx } from 'theme-ui';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Text,
  Paragraph,
  CardActions,
  Button,
} from '@verso/components';
import EyeIcon from '@fortawesome/fontawesome-free/svgs/solid/eye.svg';

function TemplateCardComponent({ template, onClickPreview, url }) {
  const image = template.metadata.object.image;
  const cardSubtitle = `${template.subject} | ${template.yearLevels[0]}`;
  const photoSubtitle = `Photo by ${image.author.name} on Unsplash`;

  return (
    <Card sx={{ textAlign: 'center', borderRadius: 'medium' }}>
      <div
        sx={{
          borderTopRightRadius: 'medium',
          borderTopLeftRadius: 'medium',
          backgroundColor: 'neutral.1',
          height: 8,
          backgroundImage: `url("${image.urls.regular}")`,
          backgroundSize: 'cover',
          backgroundPosition: '50% 50%',
          position: 'relative',
        }}
      >
        {onClickPreview && (
          <div
            onClick={onClickPreview(template)}
            sx={{
              backgroundColor: 'secondary',
              width: 2,
              height: 2,
              backgroundImage: `url("${EyeIcon}")`,
              backgroundSize: '80% 80%',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '50% 50%',
              position: 'absolute',
              top: 2,
              right: 2,
              borderRadius: 'small',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          />
        )}
        {template.metadata.object.category &&
        template.metadata.object.category.length ? (
          template.metadata.object.category.map(category => (
            <div
              key={`${template._id}-${category}`}
              sx={{
                p: 1,
                px: 2,
                m: 1,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'inline-flex',
                borderRadius: 'large',
                color: 'textWhite',
                backgroundColor: () => {
                  switch (category) {
                    case 'Contextual reflection': {
                      return '#2196F3';
                    }
                    case 'Metacognitive reflection': {
                      return '#E91E63';
                    }
                    case 'Remote learning': {
                      return '#673AB7';
                    }
                    case 'Personal reflection': {
                      return '#FF9800';
                    }
                    case 'Knowledge and understanding': {
                      return '#009688';
                    }
                    default: {
                      return '#493656';
                    }
                  }
                },
                position: 'absolute',
                bottom: 1,
                left: 1,
              }}
            >
              <Text sx={{ color: 'textWhite' }} variant="display">
                {template.metadata.object.category[0]}
              </Text>
            </div>
          ))
        ) : (
          <div />
        )}
      </div>
      <div>
        <a
          href={image.author.profile}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Text
            variant="display"
            sx={{ color: 'textDisabled', fontSize: '0.5rem' }}
          >
            {photoSubtitle}
          </Text>
        </a>
      </div>
      <CardContent sx={{ textAlign: 'center', '&': { mb: 0, mt: 0 } }}>
        <Paragraph>
          <Text variant="overline" sx={{ color: 'textDisabled' }}>
            {cardSubtitle}
          </Text>
        </Paragraph>
        <Paragraph sx={{ mb: 0 }}>
          <Text variant="subtitle">{`${template.learningObjective}`}</Text>
        </Paragraph>
      </CardContent>
      <CardActions sx={{ borderTop: 'none' }}>
        <Button
          as="a"
          href={
            url ||
            `${process.env.BASE_URL_LINK}/new-exit-ticket?templateId=${template._id}`
          }
          variant="text"
          onClick={() => {
            window.parent.postMessage(
              { message: 'getAppData', value: template._id },
              '*',
            );
            ReactGA.event({
              category: 'Activity',
              action: 'Create',
              label: `Create: ${template._id}`,
            });
          }}
        >
          Try It
        </Button>
      </CardActions>
    </Card>
  );
}

TemplateCardComponent.propTypes = {
  template: PropTypes.shape,
  onClickPreview: PropTypes.func,
  url: PropTypes.string,
};

export default TemplateCardComponent;
