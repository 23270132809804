/** @jsx jsx */
import PropTypes from 'prop-types';
import { jsx, useThemeUI, Container } from 'theme-ui';
import {
  FlexCol,
  ContentWrap,
  FlexColItem,
  Text,
  SelectEnhanced,
  Button,
  LoaderDots,
  Paragraph,
} from '@verso/components';
import Masonry from 'react-masonry-css';
import Creatable from 'react-select/creatable';
import { useState } from 'react';
import TemplateCardComponent from './TemplateCardComponent';
import FilterTag from './lib/FilterTag';
import PreviewModal from './lib/PreviewModal';
import DashboardModal from './lib/DashboardModal';

export const objectiveOptions = [
  {
    value: 0,
    label: 'I’m confused',
    labelSimple: 'Confused',
    labelFirstPerson: 'I’m confused',
    labelThirdPerson: 'is confused',
    tooltip: "I'd like some more help with...",
    image:
      'https://s3.us-west-1.amazonaws.com/verso-uploads/themes/verso/confused.png',
  },
  {
    value: 1,
    label: 'I’m almost there',
    labelSimple: 'Almost there',
    labelFirstPerson: "I'm almost there",
    labelThirdPerson: 'is almost there',
    tooltip: "I haven't quite 'got it' because I still need to...",
    image:
      'https://s3.us-west-1.amazonaws.com/verso-uploads/themes/verso/almost.png',
  },
  {
    value: 2,
    label: 'I got it!',
    labelSimple: 'Got it',
    labelFirstPerson: 'I got it',
    labelThirdPerson: 'got it',
    tooltip: 'Now I want to find out more about...',
    image:
      'https://s3.us-west-1.amazonaws.com/verso-uploads/themes/verso/gotit.png',
  },
];

export const mostHelpfulOptions = [
  {
    value: 10,
    name: 'Time to talk about the learning goals for the lesson',
  },
  { value: 11, name: 'Teacher explanation for the whole class' },
  { value: 12, name: 'Time to think and ask questions' },
  { value: 13, name: 'Models, examples and vocabulary' },
  { value: 14, name: 'Time to discuss ideas as a whole class' },
  { value: 15, name: 'Opportunities to work with my classmates' },
  { value: 16, name: 'Time to research and work on my own' },
  { value: 17, name: 'Feedback from my teacher and classmates' },
  { value: 18, name: 'One on one support from my teacher' },
];

export const legacyMostHelpfulOptions = [
  { value: 0, name: 'Time to think about my learning and ask questions' },
  { value: 1, name: 'Whole class teacher explanation' },
  { value: 2, name: 'Time to collaborate with other students' },
  { value: 3, name: 'Feedback from my teacher and other students' },
  { value: 4, name: 'Time to research and take notes' },
  { value: 5, name: 'Useful vocabulary' },
  { value: 6, name: 'Individual teacher support' },
  { value: 7, name: 'Time to discuss the today’s learning goals' },
  { value: 8, name: 'Whole class discussion' },
  {
    value: 9,
    name: 'A clear example of what’s expected for each stage of the process',
  },
  // Options used during the COVID-19 home learning season
  { value: 19, name: 'Examples of what we need to do to be successful' },
  {
    value: 20,
    name: 'Help with how to organise and effectively manage my learning',
  },
  { value: 21, name: 'Small group or individual support from my teacher' },
  {
    value: 22,
    name:
      'Clear instructions on what we need to do and how it will be assessed',
  },
  { value: 23, name: 'Feedback from my teacher' },
  { value: 24, name: 'Essential vocabulary lists' },
  {
    value: 25,
    name: 'Clearly communicated time frames for completing and submitting work',
  },
  { value: 26, name: 'Access to additional support materials' },
  { value: 27, name: 'Time to pursue my own ideas or interests' },
];

// XXX Need to hard-code the color values to avoid problem when this file is imported
// on the server-side (where `vars` isn't defined)
export const emotionalResponseCategories = [
  {
    value: 'Engaged',
    color: '#ffb759', // chartColors.chart8
  },
  {
    value: 'Disruptive',
    color: '#ec777c', // chartColors.chart6
  },
  {
    value: 'Passive',
    color: '#9c5d92', // chartColors.chart4
    inverseColor: 'white',
  },
  {
    value: 'Disheartened',
    color: '#354c78', // chartColors.chart2
    inverseColor: 'white',
  },
];

// XXX Store these values inside a collection so that we can flexibly update
// the list of values in the future?
export const emotionalResponseOptions = [
  { value: 'Annoyed', category: 'Disruptive', position: [-1, 1] },
  { value: 'Comfy', category: 'Passive', position: [2, -2] },
  { value: 'Stressed', category: 'Disruptive', position: [-1, 2] },
  { value: 'Energised', category: 'Engaged', position: [1, 2] },
  { value: 'Focused', category: 'Engaged', position: [2, 1] },
  { value: 'Inspired', category: 'Engaged', position: [2, 2] },
  { value: 'Happy', category: 'Engaged', position: [1, 1] },
  { value: 'Anxious', category: 'Disruptive', position: [-2, 1] },
  { value: 'Frustrated', category: 'Disruptive', position: [-2, 2] },
  { value: 'Discouraged', category: 'Disheartened', position: [-2, -1] },
  { value: 'Bored', category: 'Disheartened', position: [-1, -1] },
  { value: 'Hopeless', category: 'Disheartened', position: [-2, -2] },
  { value: 'Content', category: 'Passive', position: [1, -1] },
  { value: 'Satisfied', category: 'Passive', position: [2, -1] },
  { value: 'Tired', category: 'Disheartened', position: [-1, -2] },
  { value: 'Relaxed', category: 'Passive', position: [1, -2] },
];

// List of deprecated emotional values and their corresponding mapping to new values.
// For now we're using this mapping to display deprecated values on the client-side.
// A migration will be carried out in the future to update these values.
export const emotionalResponseOptionsDeprecated = [
  { value: 'Contented', newValue: 'Content' },
  { value: 'Unsure', newValue: 'Anxious' },
  { value: 'Curious', newValue: 'Focused' },
  { value: 'Valued', newValue: 'Happy' },
  { value: 'Excited', newValue: 'Energised' },
  { value: 'Upset', newValue: 'Discouraged' },
  { value: 'Overwhelmed', newValue: 'Stressed' },
  { value: 'Insecure', newValue: 'Anxious' },
  { value: 'Confident', newValue: 'Energised' },
  { value: 'Productive', newValue: 'Energised' },
  // This value will be hidden as it doesn't have a mapping
  { value: 'Challenged', newValue: null },
];

const convertEmToPx = em => {
  const container = document.getElementById(
    'student-reflection-dashboard-container',
  );
  const fontSize = container
    ? window.getComputedStyle(container, null).getPropertyValue('font-size')
    : 16;
  return parseInt(fontSize, 10) * parseInt(em, 10);
};

function EmbeddedBrowseComponent({
  subjectFilters,
  yearFilters,
  selectedFilterTags,
  onSelectTag,
  keywordFilters,
  onSelectKeyword,
  onCreateOption,
  onLoadMore,
  exitTicketTemplates = [],
  totalExitTicketsCount,
  onSelectInputChange,
  previewData,
  showExample,
  toggleShowExample,
  fetchError,
  loadingMore,
  authorityFilters,
}) {
  const currentDisplayCount =
    (exitTicketTemplates && exitTicketTemplates.length) || 0;

  const [isDashboardModalVisible, setIsDashboardModalVisible] = useState(false);
  const toggleDashboardModal = () => {
    setIsDashboardModalVisible(state => !state);
  };

  const {
    theme: { breakpoints },
  } = useThemeUI();
  const studentResponsesOneColumnBreakpoint = convertEmToPx(breakpoints[1]);
  const studentResponsesTwoColumnBreakpoint = convertEmToPx(breakpoints[3]);

  return (
    <div sx={{ overflowAnchor: 'none', mb: 4 }}>
      <ContentWrap maxWidth="contentSmall" sx={{ mx: 0 }}>
        <FlexCol
          sx={{
            justifyContent: 'center',
            pt: '48px',
            '> div': {
              // Kill default padding on flexcolitems
              px: 0,
            },
          }}
        >
          {/* quick filters */}
          <FlexColItem cols={12} sx={{ mb: 3, pt: 2 }}>
            <Text variant="heading6">Quick filters:</Text>
            <div sx={{ pb: 3, pt: 2 }}>
              {authorityFilters &&
                authorityFilters.map(filter => (
                  <FilterTag
                    key={filter.label}
                    label={filter.label}
                    count={filter.count}
                    field={filter.field}
                    active={selectedFilterTags.find(
                      tags => tags.label === filter.label,
                    )}
                    onSelectTag={onSelectTag}
                  />
                ))}
            </div>
            <div sx={{ pb: 3, pt: 2 }}>
              {subjectFilters &&
              selectedFilterTags.filter(tag =>
                ['authority'].includes(tag.fieldName),
              ).length ? (
                subjectFilters.map(filter => (
                  <FilterTag
                    key={filter.label}
                    label={filter.label}
                    count={filter.count}
                    field={filter.field}
                    active={selectedFilterTags.find(
                      tags => tags.label === filter.label,
                    )}
                    onSelectTag={onSelectTag}
                  />
                ))
              ) : (
                <div />
              )}
            </div>
            <div>
              {yearFilters &&
              selectedFilterTags.filter(tag =>
                ['subject'].includes(tag.fieldName),
              ).length ? (
                yearFilters.map(filter => (
                  <FilterTag
                    key={filter.label}
                    label={filter.label}
                    count={filter.count}
                    field={filter.field}
                    active={selectedFilterTags.find(
                      tags => tags.label === filter.label,
                    )}
                    onSelectTag={onSelectTag}
                  />
                ))
              ) : (
                <div />
              )}
            </div>
          </FlexColItem>

          {/* keyword filter */}
          <FlexColItem cols={12} sx={{ mb: 4 }}>
            <Text variant="heading6">
              Filter by topic, concept, skill or keyword:
            </Text>
            <SelectEnhanced
              isMulti
              as={Creatable}
              id="keyword"
              placeholder="Type to search or add filters"
              value={
                selectedFilterTags &&
                selectedFilterTags
                  .filter(
                    key =>
                      !['subject', 'yearLevels', 'partialText'].includes(
                        key.fieldName,
                      ),
                  )
                  .map(keyword => ({
                    value: keyword.fieldName,
                    label: keyword.label,
                  }))
              }
              onInputChange={onSelectInputChange}
              onChange={onSelectKeyword}
              options={keywordFilters || []}
              onCreateOption={onCreateOption}
            />
          </FlexColItem>

          {/* card template list */}
          <FlexColItem
            cols={12}
            sx={{ borderTop: '1px solid rgba(0, 0, 0, 0.38)', mb: 3 }}
          >
            <Text variant="caption" sx={{ pt: 1 }}>
              Showing {currentDisplayCount} of {totalExitTicketsCount} Check-ins
            </Text>
          </FlexColItem>
          {exitTicketTemplates && exitTicketTemplates.length ? (
            <Masonry
              breakpointCols={{
                default: 4,
                [studentResponsesOneColumnBreakpoint]: 2,
                [studentResponsesTwoColumnBreakpoint]: 3,
              }}
              columnClassName="masonry-column"
              sx={{
                width: '100%',
                display: 'flex',
                mx: theme => [-2, -theme.space[4] / 2],
              }}
            >
              {exitTicketTemplates.map(template => (
                <Container
                  key={template._id}
                  sx={{
                    p: theme => theme.space[4] / 2,
                    py: theme => [2, theme.space[4] / 2],
                  }}
                >
                  <TemplateCardComponent template={template} url="#" />
                </Container>
              ))}
            </Masonry>
          ) : fetchError ? (
            <Paragraph>{fetchError}</Paragraph>
          ) : (
            <LoaderDots sx={{ my: 5, color: 'primaryLight' }} size={2} />
          )}
        </FlexCol>
        {currentDisplayCount !== totalExitTicketsCount && (
          <div
            sx={{
              display: 'flex',
              justifyItems: 'center',
              justifyContent: 'center',
              pt: 4,
            }}
          >
            <Button
              sx={{ mt: 4 }}
              variant="text"
              onClick={onLoadMore}
              loading={loadingMore}
            >
              show more
            </Button>
          </div>
        )}
      </ContentWrap>

      <PreviewModal
        {...previewData}
        toggleShowExample={toggleShowExample}
        showExample={showExample}
      />

      <DashboardModal
        visible={isDashboardModalVisible}
        onClose={toggleDashboardModal}
      />
    </div>
  );
}

EmbeddedBrowseComponent.propTypes = {
  subjectFilters: PropTypes.arrayOf(PropTypes.shape),
  authorityFilters: PropTypes.arrayOf(PropTypes.shape),
  yearFilters: PropTypes.arrayOf(PropTypes.shape),
  selectedFilterTags: PropTypes.arrayOf(PropTypes.shape),
  onSelectTag: PropTypes.func,
  keywordFilters: PropTypes.arrayOf(PropTypes.shape),
  onSelectKeyword: PropTypes.func,
  onCreateOption: PropTypes.func,
  onLoadMore: PropTypes.func,
  exitTicketTemplates: PropTypes.arrayOf(PropTypes.shape),
  totalExitTicketsCount: PropTypes.number,
  onSelectInputChange: PropTypes.func,
  previewData: PropTypes.shape,
  showExample: PropTypes.bool,
  toggleShowExample: PropTypes.func,
  onClickPreview: PropTypes.func,
  fetchError: PropTypes.string,
  loadingMore: PropTypes.bool,
  popularTags: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      field: PropTypes.string,
    }),
  ),
};

export default EmbeddedBrowseComponent;
