/** @jsx jsx */
import ReactGA from 'react-ga';
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';
import Modal from '@verso/components/lib/components/Modal/Modal';
import {
  ModalHeader,
  ModalContent,
  Paragraph,
  Heading,
  ModalControls,
  useForm,
  CheckInResponseContent,
  Dimmer,
  Button,
} from '@verso/components';

// CAUTION: There is custom validation in the schema that explicitly references these field values
export const objectiveOptions = [
  {
    value: 0,
    label: 'I’m confused',
    labelSimple: 'Confused',
    labelFirstPerson: 'I’m confused',
    labelThirdPerson: 'is confused',
    tooltip: "I'd like some more help with...",
    image:
      'https://s3.us-west-1.amazonaws.com/verso-uploads/themes/verso/confused.png',
  },
  {
    value: 1,
    label: 'I’m almost there',
    labelSimple: 'Almost there',
    labelFirstPerson: "I'm almost there",
    labelThirdPerson: 'is almost there',
    tooltip: "I haven't quite 'got it' because I still need to...",
    image:
      'https://s3.us-west-1.amazonaws.com/verso-uploads/themes/verso/almost.png',
  },
  {
    value: 2,
    label: 'I got it!',
    labelSimple: 'Got it',
    labelFirstPerson: 'I got it',
    labelThirdPerson: 'got it',
    tooltip: 'Now I want to find out more about...',
    image:
      'https://s3.us-west-1.amazonaws.com/verso-uploads/themes/verso/gotit.png',
  },
];

export const mostHelpfulOptions = [
  {
    value: 10,
    name: 'Time to talk about the learning goals for the lesson',
  },
  { value: 11, name: 'Teacher explanation for the whole class' },
  { value: 12, name: 'Time to think and ask questions' },
  { value: 13, name: 'Models, examples and vocabulary' },
  { value: 14, name: 'Time to discuss ideas as a whole class' },
  { value: 15, name: 'Opportunities to work with my classmates' },
  { value: 16, name: 'Time to research and work on my own' },
  { value: 17, name: 'Feedback from my teacher and classmates' },
  { value: 18, name: 'One on one support from my teacher' },
];

// XXX Store these values inside a collection so that we can flexibly update
// the list of values in the future?
export const emotionalResponseOptions = [
  { value: 'Annoyed', category: 'Disruptive', position: [-1, 1] },
  { value: 'Comfy', category: 'Passive', position: [2, -2] },
  { value: 'Stressed', category: 'Disruptive', position: [-1, 2] },
  { value: 'Energised', category: 'Engaged', position: [1, 2] },
  { value: 'Focused', category: 'Engaged', position: [2, 1] },
  { value: 'Inspired', category: 'Engaged', position: [2, 2] },
  { value: 'Happy', category: 'Engaged', position: [1, 1] },
  { value: 'Anxious', category: 'Disruptive', position: [-2, 1] },
  { value: 'Frustrated', category: 'Disruptive', position: [-2, 2] },
  { value: 'Discouraged', category: 'Disheartened', position: [-2, -1] },
  { value: 'Bored', category: 'Disheartened', position: [-1, -1] },
  { value: 'Hopeless', category: 'Disheartened', position: [-2, -2] },
  { value: 'Content', category: 'Passive', position: [1, -1] },
  { value: 'Satisfied', category: 'Passive', position: [2, -1] },
  { value: 'Tired', category: 'Disheartened', position: [-1, -2] },
  { value: 'Relaxed', category: 'Passive', position: [1, -2] },
];

function PreviewModal({
  title,
  reflectionPrompt,
  toggleShowExample,
  showExample,
  templateId,
  image,
}) {
  // Mock interactivity for student preview modal
  const { formState, setFormState, onChange } = useForm({
    initialState: {
      emotionalResponse: [],
      objective: null,
      inquiry: '',
      takeAway: '',
      mostHelpful: null,
      followUp: false,
    },
  });

  const customOnChange = event => {
    const { currentTarget } = event;
    const name = currentTarget.name || currentTarget.getAttribute('name');

    switch (name) {
      case 'followUp':
        return setFormState(state => ({
          ...state,
          [name]: currentTarget.checked,
        }));
      case 'emotionalResponse': {
        const { innerText } = currentTarget;
        return formState.emotionalResponse.includes(innerText)
          ? setFormState(state => ({
              ...state,
              [name]: state[name].filter(word => word !== innerText),
            }))
          : setFormState(state => ({
              ...state,
              [name]: [...state[name], innerText].slice(-2),
            }));
      }
      case 'objective': {
        const value =
          currentTarget.value || currentTarget.getAttribute('value');
        return setFormState(state => ({
          ...state,
          [name]: parseInt(value, 10),
        }));
      }
      default:
        onChange(event);
    }
  };

  return (
    <Dimmer visible={showExample} onClickOutside={toggleShowExample}>
      <div sx={{ pt: 7 }}>
        <Modal
          variant="large"
          sx={{
            mb: 4,
            backgroundColor: 'alerts.information',
          }}
        >
          <ModalControls
            onClose={toggleShowExample}
            sx={{ '& svg': { fill: 'white' } }}
          />
          <ModalHeader>
            <Heading as="h3" variant="heading5" sx={{ color: 'textWhite' }}>
              This is what your students will see
            </Heading>
          </ModalHeader>
          <ModalContent sx={{ color: 'textWhite', mt: 'unset' }}>
            <Paragraph>
              You can make adjustments and preview changes before inviting them
              to respond.
            </Paragraph>
            <Paragraph>
              This check-in includes a reflection prompt focussed on Contextual
              Reflection. While editing you can create your own or choose from
              over 150 prompts designed to target inquiry, critical and creative
              thinking, self awareness and more.
            </Paragraph>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: 'transparent',
                borderColor: 'textWhite',
                color: 'textWhite',
                '&:hover': { backgroundColor: 'transparent', boxShadow: 3 },
              }}
              as="a"
              href={`${process.env.BASE_URL_LINK}/new-exit-ticket?templateId=${templateId}`}
              target="_blank"
              onClick={() => {
                ReactGA.event({
                  category: 'Activity',
                  action: 'Create',
                  label: `Create: ${templateId}`,
                });
              }}
            >
              Edit and try this check-in
            </Button>
          </ModalContent>
        </Modal>
        <Modal
          variant="large"
          sx={{
            backgroundColor: 'neutral.0',
          }}
        >
          <ModalHeader>
            <div
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1,
                backgroundImage: `url("${image ||
                  'https://media.versoapp.com/Images/StudentReflectionCardBackground.png'}")`,
                backgroundSize: 'cover',
                backgroundPosition: '50% 50%',
              }}
              src={
                formState.imageUrl ||
                'https://media.versoapp.com/Images/StudentReflectionCardBackground.png'
              }
              alt="background image"
            >
              <div
                sx={{
                  position: 'absolute',
                  bg: 'primaryLight',
                  opacity: 0.6,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>
            <Heading
              as="h1"
              variant="heading5"
              sx={{
                zIndex: 2,
                textAlign: 'center',
                pt: 5,
                pb: 6,
                width: '100%',
                color: 'textWhite',
              }}
            >
              We are learning to {title || 'Your learning intention goes here'}
            </Heading>
          </ModalHeader>
          <ModalContent>
            <CheckInResponseContent
              objectiveOptions={objectiveOptions}
              mostHelpfulOptions={mostHelpfulOptions}
              emotionalResponse={emotionalResponseOptions.map(
                emotion => emotion.value,
              )}
              formState={formState}
              onSubmit={toggleShowExample}
              onChange={customOnChange}
              title={title || 'Your learning intention goes here'}
              reflectionPrompt={reflectionPrompt}
              errors={{}}
              example
              klass={{}}
              createdAt={new Date()}
              isSubmitting={false}
            />
          </ModalContent>
        </Modal>
      </div>
    </Dimmer>
  );
}

PreviewModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  formData: PropTypes.shape({
    objective: PropTypes.number,
    mostHelpful: PropTypes.number.isRequired,
    takeAway: PropTypes.string,
    inquirySupport: PropTypes.string,
    inquiryQuestions: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    mostHelpful: PropTypes.number,
    takeAway: PropTypes.string,
    inquirySupport: PropTypes.string,
    inquiryQuestions: PropTypes.string,
  }).isRequired,
  onChangeCheckbox: PropTypes.func.isRequired,
  example: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  reflectionPrompt: PropTypes.string,
  toggleShowExample: PropTypes.func,
  showExample: PropTypes.bool,
  templateId: PropTypes.string,
  image: PropTypes.string,
};

PreviewModal.defaultProps = {
  example: false,
  reflectionPrompt:
    "Try to summarise the main idea of today's lesson in 25 words or less",
};

export default PreviewModal;
