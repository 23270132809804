import { Router, globalHistory } from '@reach/router';
import React from 'react';
import { ThemeProvider } from '@verso/components';
import theme from '@verso/components/lib/themes/verso/theme';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import ReactGA from 'react-ga';
import { QueryParamProvider } from 'use-query-params';
import NotFound from './NotFound';
import BrowseContainer from './BrowseContainer';
import EmbeddedBrowseContainer from './EmbeddedBrowseContainer';

const client = new ApolloClient({
  uri: process.env.GRAPHQL_URL || 'https://new-staging.versoapp.com/graphql',
  cache: new InMemoryCache(),
});

ReactGA.initialize('UA-44287222-6');

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <QueryParamProvider reachHistory={globalHistory}>
          <Router>
            <BrowseContainer path="/" />
            <EmbeddedBrowseContainer path="/embed" />
            <NotFound default />
          </Router>
        </QueryParamProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default App;
