/** @jsx jsx */
import { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { jsx } from 'theme-ui';
import { useQuery, useLazyQuery, gql } from '@apollo/client';
import _ from 'underscore';
import { get, debounce, timeoutPromise } from '../lib/utils';
import EmbeddedBrowseComponent from './EmbeddedBrowseComponent';

function EmbeddedBrowseContainer() {
  const [selectedFilterTags, setSelectedFilterTags] = useState([]);
  const onSelectTag = ({ label, field }) => () => {
    if (selectedFilterTags.find(tag => tag.label === label)) {
      setSelectedFilterTags(state => state.filter(tag => tag.label !== label));
    } else {
      ReactGA.event({
        category: 'Activity',
        action: 'Filter',
        label: `Filter: ${label}`,
      });
      setSelectedFilterTags(state => [...state, { fieldName: field, label }]);
    }
  };
  const [exitTicketTemplates, setExitTicketTemplates] = useState([]);
  const [totalExitTicketsCount, setTotalExitTicketsCount] = useState(0);
  const [fetchError, setFetchError] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    const variables = {
      filter: selectedFilterTags.length
        ? selectedFilterTags.reduce((filterTags, tag) => {
            return filterTags[tag.fieldName]
              ? {
                  ...filterTags,
                  [tag.fieldName]: [...filterTags[tag.fieldName], tag.label],
                }
              : { ...filterTags, [tag.fieldName]: [tag.label] };
          }, {})
        : {},
    };

    timeoutPromise(
      10000,
      fetch('/.netlify/functions/fetch_templates', {
        method: 'POST',
        body: JSON.stringify(variables),
      }),
    )
      .then(response => {
        if (!response.ok) throw response;
        return response.json();
      })
      .then(responseJSON => {
        setExitTicketTemplates(
          get(responseJSON, 'data.exitTicketTemplates.exitTicketTemplates', {}),
        );
        const totalCount = get(
          responseJSON,
          'data.exitTicketTemplatesCount.totalCount',
          0,
        );
        setTotalExitTicketsCount(totalCount);

        if (totalCount === 0) {
          setFetchError(
            'Sorry, we couldn’t find any Check-ins for this filter combination. Adjust your filter selections to try again.',
          );
        }
      })
      .catch(() =>
        setFetchError(
          'We seem to be having trouble connecting to our servers, try refreshing the page or selecting a new filter.',
        ),
      );
  }, [selectedFilterTags]);

  const filterOptionFields = `            
    field
    label
    count
    unfilteredCount
  `;

  const { data: subjectFilterCacheValue = {} } = useQuery(
    gql`
      query BrowseCatalogueQuickFilterSubjects($filter: GraphQLObject, $options: FilterOptions) {
        subjectOptions: exitTicketTemplatesFilterOptions(filter: $filter, options: $options, fields: ["subject"]) {
          ${filterOptionFields}
        }
      }
    `,
    {
      variables: {
        options: { unwindArrays: true },
        filter: selectedFilterTags.length
          ? selectedFilterTags
              .filter(tag => ['authority'].includes(tag.fieldName))
              .reduce((filterTags, tag) => {
                return filterTags[tag.fieldName]
                  ? {
                      [tag.fieldName]: [
                        ...filterTags[tag.fieldName],
                        tag.label,
                      ],
                    }
                  : { ...filterTags, [tag.fieldName]: [tag.label] };
              }, {})
          : {},
      },
    },
  );

  const { data: authorityFilterCacheValue = {} } = useQuery(
    gql`
      query BrowseCatalogueQuickFilterSubjects {
        authorityOptions: exitTicketTemplatesFilterOptions(fields: ["authority"]) {
          ${filterOptionFields}
        }
      }
    `,
  );

  const {
    loading: loadingYearFilters,
    data: yearFilterCacheValue = {},
  } = useQuery(
    gql`
  query BrowseCatalogueQuickFilterYears($filter: GraphQLObject, $options: FilterOptions) {
    yearLevelOptions: exitTicketTemplatesFilterOptions(filter: $filter, fields: ["yearLevels"], options: $options) {
      ${filterOptionFields}
    }
  }
`,
    {
      variables: {
        options: { unwindArrays: true },
        filter: selectedFilterTags.length
          ? selectedFilterTags
              .filter(tag => ['subject', 'authority'].includes(tag.fieldName))
              .reduce((filterTags, tag) => {
                return filterTags[tag.fieldName]
                  ? {
                      [tag.fieldName]: [
                        ...filterTags[tag.fieldName],
                        tag.label,
                      ],
                    }
                  : { ...filterTags, [tag.fieldName]: [tag.label] };
              }, {})
          : {},
      },
    },
  );

  // Only load keywords once we have filters in place, or have started typing into the dropdown field
  const [
    getKeywords,
    { loading: loadingKeywordFilters, data: keywordFilterCacheValue = {} }, //eslint-disable-line
  ] = useLazyQuery(
    gql`
  query BrowseCatalogueKeywordFilter($filter: GraphQLObject, $options: FilterOptions) {
    keywordOptions: exitTicketsTemplateKeywordsFilter(filter: $filter, options: $options, fields: ["standard"]) {
      ${filterOptionFields}
    }
  }
`,
    {
      variables: {
        options: { unwindArrays: true },
        filter: selectedFilterTags.length
          ? selectedFilterTags.reduce((filterTags, tag) => {
              return filterTags[tag.fieldName]
                ? {
                    [tag.fieldName]: [...filterTags[tag.fieldName], tag.label],
                  }
                : { ...filterTags, [tag.fieldName]: [tag.label] };
            }, {})
          : {},
      },
    },
  );

  useEffect(() => {
    getKeywords();
  }, []) // eslint-disable-line

  useEffect(() => {
    if (selectedFilterTags.length) {
      debounce(getKeywords(), 500);
    }
  }, [selectedFilterTags, getKeywords]);

  const keywordFilters = get(
    keywordFilterCacheValue,
    'keywordOptions',
    [],
  ).filter(key => !['subject', 'yearLevels'].includes(key.field));

  const groupedKeywordFilters = _.groupBy(keywordFilters.slice(), 'field');
  const finalKeywordList = Object.keys(groupedKeywordFilters).map(
    keywordGroup => ({
      label: keywordGroup,
      options: groupedKeywordFilters[keywordGroup].map(option => ({
        value: keywordGroup,
        label: option.label,
      })),
    }),
  );

  const subjectFilters = get(subjectFilterCacheValue, 'subjectOptions', [])
    .slice()
    .sort((a, b) => a.label.localeCompare(b.label))
    .filter(tag => tag.count !== 0);

  const authorityFilters = get(
    authorityFilterCacheValue,
    'authorityOptions',
    [],
  )
    .slice()
    .sort((a, b) => a.label.localeCompare(b.label));

  const [yearFilters, setYearFilters] = useState([]); // Use state to prevent flashing of filters on updates
  useEffect(() => {
    if (
      !loadingYearFilters &&
      get(yearFilterCacheValue, 'yearLevelOptions', []).length
    ) {
      const yearLevelOptions = get(yearFilterCacheValue, 'yearLevelOptions', [])
        .slice()
        .filter(tag => tag.count !== 0);

      yearLevelOptions.sort((a, b) => {
        const num1 = Number(a.label.split(' ').pop());
        const num2 = Number(b.label.split(' ').pop());

        if (isNaN(num1)) return -1;
        if (isNaN(num2)) return 1;

        return num1 - num2;
      });
      setYearFilters(yearLevelOptions);
    }
  }, [loadingYearFilters, yearFilterCacheValue]);

  const [previewData, setPreviewData] = useState({
    formData: { objective: 2 },
    errors: {},
  });
  const [showExample, setShowExample] = useState(false);

  const toggleShowExample = event => {
    event.preventDefault();
    setShowExample(state => !state);
  };

  const onClickPreview = template => () => {
    ReactGA.event({
      category: 'Activity',
      action: 'Preview',
      label: `Preview: ${template._id}`,
    });

    setPreviewData(state => ({
      ...state,
      title: template.learningObjective,
      reflectionPrompt: template.reflectionPrompt,
      templateId: template._id,
      image: get(template, 'metadata.object.image.urls.regular', null),
    }));
    setShowExample(true);
  };

  const createOption = option => {
    setSelectedFilterTags(state => [
      ...state,
      { fieldName: '$text', label: option },
    ]);
  };

  const popularTags = [
    {
      field: 'subject',
      label: 'Science',
    },
    {
      field: 'yearLevels',
      label: 'Year 7',
    },
    {
      field: 'yearLevels',
      label: 'Year 8',
    },
    {
      field: 'yearLevels',
      label: 'Year 9',
    },
    {
      field: 'studyAreas',
      label: 'Earth Resources',
    },
    {
      field: 'studyAreas',
      label: 'Inquiry and skills',
    },
    {
      field: 'studyAreas',
      label: 'Earth in Space',
    },
    {
      field: 'category',
      label: 'Remote Learning',
    },
    {
      field: 'context',
      label: 'Pearson',
    },
    {
      field: 'studyAreas',
      label: 'Working Scientifically',
    },
  ];

  return (
    <EmbeddedBrowseComponent
      popularTags={popularTags}
      fetchError={fetchError}
      setShowExample={setShowExample}
      onClickPreview={onClickPreview}
      toggleShowExample={toggleShowExample}
      previewData={previewData}
      showExample={showExample}
      exitTicketTemplates={exitTicketTemplates}
      totalExitTicketsCount={totalExitTicketsCount}
      authorityFilters={authorityFilters}
      subjectFilters={subjectFilters}
      yearFilters={yearFilters}
      selectedFilterTags={selectedFilterTags}
      onSelectTag={onSelectTag}
      keywordFilters={finalKeywordList}
      loadingMore={loadingMore}
      onSelectInputChange={(newValue, { action }) => {
        if (action === 'menu-close') {
          return setSelectedFilterTags(state => [
            ...state.filter(tag => tag.fieldName !== 'partialText'),
          ]);
        }
        if (newValue)
          setSelectedFilterTags(state => [
            ...state.filter(tag => tag.fieldName !== 'partialText'),
            { label: newValue, fieldName: 'partialText' },
          ]);
      }}
      onSelectKeyword={({ currentTarget }) => {
        const { value } = currentTarget;

        if (
          value &&
          /Create/.test(value[value.length - 1].label) &&
          value[value.length - 1].value !== 'verb'
        ) {
          return createOption(value[value.length - 1].value);
        }

        if (value) {
          ReactGA.event({
            category: 'Activity',
            action: 'Filter',
            label: `Filter: ${value[value.length - 1].label}`,
          });
        }

        !value
          ? setSelectedFilterTags(state =>
              state.filter(tag =>
                ['subject', 'yearLevels'].includes(tag.fieldName),
              ),
            )
          : setSelectedFilterTags(state => [
              ...state.filter(tag =>
                ['subject', 'yearLevels'].includes(tag.fieldName),
              ),
              ...value.map(newTag => {
                return {
                  label: newTag['label'],
                  fieldName: newTag['value'],
                };
              }),
            ]);
      }}
      onCreateOption={option => createOption(option)}
      onLoadMore={() => {
        const scrollY = window.scrollY;
        setLoadingMore(true);
        const variables = {
          offset: exitTicketTemplates.length,
          filter: selectedFilterTags.length
            ? selectedFilterTags.reduce((filterTags, tag) => {
                return filterTags[tag.fieldName]
                  ? {
                      [tag.fieldName]: [
                        ...filterTags[tag.fieldName],
                        tag.label,
                      ],
                    }
                  : { ...filterTags, [tag.fieldName]: [tag.label] };
              }, {})
            : {},
        };

        fetch('/.netlify/functions/fetch_templates', {
          method: 'POST',
          body: JSON.stringify(variables),
        })
          .then(response => {
            return response.json();
          })
          .then(responseJSON => {
            window.scrollTo(window.scrollX, scrollY);

            const templates = exitTicketTemplates.concat(
              ...get(
                responseJSON,
                'data.exitTicketTemplates.exitTicketTemplates',
                [],
              ),
            );
            setLoadingMore(false);
            setExitTicketTemplates(templates);
          });
      }}
    />
  );
}

export default EmbeddedBrowseContainer;
